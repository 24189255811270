import standard from "../../assets/image/standard-1.jpg";
import premiuim from "../../assets/image/premium-1.jpg";

const pricingPlanData = [
  {
    title: "BASIC",
    description: "Responsive Website With Booking System + Dispatch System",
    image: "/basic-2.jpg",
    price: "$99",
    setupFee: "$199*",
    orderLink: "http://admin.atozdispatch.com/buy/prod_QHeMpfqc1V3d6r",
    features: [
      "Web Booker",
      "Mobile-Responsive Website with Hosting & tech support",
      "Passenger account Register",
      "Unlimited Passengers",
      "Driver account register",
      "Unlimited Drivers",
      "Driver Reports",
      "CMS",
      "Booking wizard",
      "Booking Management",
      "Dispatch System",
      "SMS/email notifications",
      "Flight Tracking",
      "Job Text / Email Reminder (Passenger / Driver)",
      "Fleet Manager",
      "Driver Manager",
      "Driver Scheduling",
      "Passenger manager",
      "Rate manager",
      "Location Manager",
      "Additional Rate manager",
      "Card Payment",
      "Free Weekly New Feature Releases",
      "Delivery 2-3 Working Days",
    ],
  },
  {
    title: "STANDARD",
    description: "Passenger Booking App  +  Driver App with Dispatch System",
    image: standard,
    price: "$149",
    setupFee: "$399*",
    orderLink: "http://admin.atozdispatch.com/buy/prod_QHePshqSOnCtiy",
    features: [
      "Passenger Booking App",
      "Driver App",
      "Passenger Account Register",
      "Unlimited Passengers",
      "Driver Account Register",
      "Unlimited Drivers",
      "Driver Reports",
      "CMS",
      "Booking wizard",
      "Booking Management",
      "Dispatch System",
      "SMS/email notifications",
      "Flight Tracking",
      "Job Text / Email Reminder (Passenger / Driver)",
      "Fleet manager",
      "Driver Manager",
      "Driver Scheduling",
      "passenger manager",
      "Rate manager",
      "Location Manager",
      "Additional rate manager",
      "Card Payment",
      "Free Weekly New Feature Releases",
      "Delivery 2-3 working Days",
    ],
  },
  {
    title: "PREMIUM",
    description:
      "Responsive Website With Booking System and Dispatch System + Passenger Booking App + Driver App",
    image: premiuim,
    price: "$199",
    setupFee: "$449*",
    orderLink: "http://admin.atozdispatch.com/buy/prod_QHeVRxoViZHWAO",
    features: [
      "Web Booker",
      "Mobile-Responsive Website with Hosting & tech support",
      "Passenger Booking App",
      "Driver App",
      "Passenger Account Register",
      "Unlimited Passengers",
      "Driver Account Register",
      "Unlimited Drivers",
      "Driver Reports",
      "CMS",
      "Booking wizard",
      "Booking Management",
      "Dispatch System",
      "SMS/email notifications",
      "Flight Tracking",
      "Job Text / Email Reminder (Passenger / Driver)",
      "Fleet Manager",
      "Driver Manager",
      "Driver Scheduling",
      "passenger Manager",
      "Rate Manager",
      "Location Manager",
      "Additional Rate Manager",
      "Card Payment",
      "Free Weekly New Feature Releases",
      "Delivery 2-3 working Days",
    ],
  },
];

export default pricingPlanData;
