import { Button, Modal } from "antd";
import React, { useRef, useState } from "react";
import { TiTimes } from "react-icons/ti";
import SignatureCanvas from "react-signature-canvas";
import { IoMdCloudUpload } from "react-icons/io";
import { IoIosSave } from "react-icons/io";

const AgreementModal = ({
  open,
  handleClose,
  agreementInfo,
  handleSubmission,
  setSignatureImg,
  setFormData,
  formData,
  setIsAgreed,
  isAgreed,
}) => {
  const sigCanvas = useRef({});
  const inputRef = useRef();
  const [signature, setSignature] = useState(null);
  const [isImageInput, setIsImageInput] = useState(false);
  const [errorState, setErrorState] = useState({
    isError: false,
    errorMessage: "",
  });

  const handleSignImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignature(reader.result);
        setIsImageInput(true);
        setErrorState({
          isError: false,
          errorMessage: "",
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const clear = () => {
    setSignature(null);
    sigCanvas?.current?.clear();
    setErrorState({
      isError: false,
      errorMessage: "",
    });
    setIsImageInput(false);
  };

  const save = () => {
    setErrorState({
      isError: false,
      errorMessage: "",
    });
    const signatureData = sigCanvas?.current
      ?.getTrimmedCanvas()
      ?.toDataURL("image/png");
    setSignature(signatureData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!signature) {
      setErrorState({
        isError: true,
        errorMessage: "Please provide a valid signature",
      });
      return;
    }
    setIsAgreed(true);
    setSignatureImg(signature);

    const updatedFormData = {
      ...formData,
      signature: signature,
    };

    setFormData(updatedFormData);
    handleClose();
    handleSubmission(updatedFormData);
  };

  const getPlanPrice = (plan) => {
    let price;
    switch (plan) {
      case "basic":
        price = "$99";
        break;
      case "standard":
        price = "$149";
        break;
      case "premium":
        price = "$199";
        break;
      default:
        price = "Invalid plan";
    }
    return price;
  };

  console.log(agreementInfo);

  return (
    <Modal
      footer={null}
      open={open}
      centered
      width={1200}
      onCancel={handleClose}
    >
      <div className="ag-modal-ctn">
        <h2>Agreement</h2>
        <div style={{ height: "550px", overflowY: "auto" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <div>
              <h3 style={{ fontWeight: "bold" }}>
                Subscription Agreement for AtoZ
              </h3>
              <p>
                This Subscription Agreement is entered into by and between{" "}
                <span className="ag-label-text">{agreementInfo?.name} </span>,
                located at{" "}
                <span className="ag-label-text">
                  {agreementInfo?.address},{agreementInfo?.country}
                </span>{" "}
                , and <span className="ag-label-text">Anil Shrestha</span>{" "}
                (“Service Provider”), regarding the product AtoZ Dispatch as of{" "}
                {getCurrentDate()}.
              </p>
            </div>

            <div>
              <h3 style={{ fontWeight: "bold" }}>WHEREAS</h3>
              <p>
                Client has reviewed the demo of AtoZ Dispatch System {"   "}
                <span style={{ fontWeight: "bold" }}>
                  {agreementInfo?.plan}{" "}
                </span>{" "}
                provided at{" "}
                <a target="_blank" href="https://atozdispatch.com">
                  https://atozdispatch.com
                </a>{" "}
                and has expressed satisfaction with the demo.
              </p>
              <p>Client is ready to pay the monthly fee for the Product.</p>
            </div>

            <div>
              <h3 style={{ fontWeight: "bold" }}>NOW, THEREFORE</h3>
              <p>
                In consideration of the mutual promises and covenants contained
                herein, the parties agree as follows
              </p>
            </div>

            <div>
              <h3 style={{ fontWeight: "bold" }}>Payment Terms</h3>
              <p>
                1.Client agrees to pay monthly fee of{" "}
                <span style={{ fontWeight: "bold" }}>
                  {getPlanPrice(agreementInfo?.plan?.toLowerCase())}
                </span>{" "}
                for the use of the product [AtoZ {agreementInfo?.plan} Package
                ].
              </p>
              <p>
                2.Client acknowledges that they are aware of the fees and the
                services they will receive in return for the payment.
              </p>
            </div>

            <div>
              <h3 style={{ fontWeight: "bold" }}>Subscription Cancellation</h3>
              <p>
                1. Client has the right to cancel the subscription at any time
                before or during the due date without incurring any penalty.
              </p>
              <p>
                2. Client acknowledges that once they are charged for the
                subscription, they have no right to request a refund. However,
                they may cancel the subscription for future billing cycles.
              </p>
            </div>

            <div>
              <h3 style={{ fontWeight: "bold" }}>
                Effects of Subscription Cancellation
              </h3>
              <p>
                1. Client understands that upon cancellation of the
                subscription, their application and access to the product [A to
                Z Dispatch System] will be made offline and removed from the app
                store, rendering it no longer accessible
              </p>
              <p>
                2. In unpaid case, the application will remain accessible for 30
                days. After 30 days, the application will be cancelled status
                and will be taken offline.
              </p>
              <p>
                3. User data and account information will be completely deleted
                24 hours after cancellation.
              </p>
              <p>
                4. Client understands that upon re-subscribe the product, the
                application will be set up as a new project.
              </p>
            </div>

            <div>
              <h3 style={{ fontWeight: "bold" }}>Authorization</h3>
              <p>
                1. Client hereby authorizes the monthly subscription fee until
                they cancel the subscription.
              </p>
              <p>
                2. Service provider authorizes the delivery of the advertised
                product.
              </p>
            </div>

            <div>
              <h3 style={{ fontWeight: "bold" }}>
                Additional Terms and Information
              </h3>
              <p>
                1. Source Code: The source code for the application is not
                provided to the Client.
              </p>
              <p>
                2. Application Setup:Setup of the application will begin only
                after the necessary information is provided by the Client and
                will be completed within 7 days, assuming all required
                information is received.
              </p>
              <p>
                3. Training Support: Training support will be provided to the
                Client.{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://calendly.com/coredreams/a-to-z-dispatch-40-min"
                >
                  Click here for Schedule an appointment
                </a>
              </p>
              <p>
                4. Support Ticket Facility: A support ticket facility is
                available in the user admin backend.{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://coredreamsltd.atlassian.net/servicedesk/customer/portal/4/group/19/create/53"
                >
                  Here is the link for creating the ticket
                </a>
                .
              </p>

              <p>
                By signing below, the parties agree to be bound by the terms and
                conditions of this Agreement.
              </p>
            </div>
          </div>

          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
              }}
            >
              <div className="">
                <div style={{ display: "flex" }}>
                  <p className="ag-label">Name</p>
                  <p style={{ borderBottom: "1px solid #ec6408" }}>
                    : {agreementInfo?.name}
                  </p>
                </div>
              </div>
              <div className="">
                <div style={{ display: "flex" }}>
                  <p className="ag-label">Phone</p>
                  <p style={{ borderBottom: "1px solid #ec6408" }}>
                    : {agreementInfo?.phone}
                  </p>
                </div>
              </div>
              <div className="">
                <div style={{ display: "flex" }}>
                  <p className="ag-label">E-mail</p>
                  <p style={{ borderBottom: "1px solid #ec6408" }}>
                    : {agreementInfo?.email}
                  </p>
                </div>
              </div>
              <div className="">
                <div style={{ display: "flex" }}>
                  <p className="ag-label">Address</p>
                  <p style={{ borderBottom: "1px solid #ec6408" }}>
                    : {agreementInfo?.address},{agreementInfo?.city},
                    {agreementInfo?.state},{agreementInfo?.country}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="ag-footer">
            <div>
              <div
                style={{
                  width: "200px",
                  height: "150px",
                  border: "1px dotted rgba(213 206 202)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                {signature ? (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      // background: "#F0EFEC",
                    }}
                    src={signature}
                    alt=""
                  />
                ) : (
                  <p
                    style={{
                      color: "#ededed",
                      fontSize: "16px",
                      fontWeight: "bolder",
                    }}
                  >
                    No signature found
                  </p>
                )}
              </div>

              <p
                style={{ borderTop: "1px solid #ededed" }}
                className="signature-label"
              >
                (signature preview)
              </p>
            </div>
            <div className="signature-ctn">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "7px",
                  alignItems: "flex-end",
                  marginTop: "7px",
                  height: "100%",
                }}
              >
                <input
                  onChange={handleSignImage}
                  ref={inputRef}
                  style={{ display: "none" }}
                  type="file"
                />
              </div>
              <div
                style={{
                  width: "200px",
                  // height: "200px",
                  position: "relative",
                }}
              >
                <div>
                  <SignatureCanvas
                    penColor="black"
                    ref={sigCanvas}
                    canvasProps={{
                      width: 200,
                      height: 200,
                      className: "signatureCanvas",
                    }}
                  />

                  {errorState.isError ? (
                    <p className="signature-label">
                      ({errorState.errorMessage})
                    </p>
                  ) : (
                    <p style={{ fontSize: "14px" }} className="signature-label">
                      (Use this signature pad to draw or upload signature)
                    </p>
                  )}
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "-110px",
                    display: "flex",
                    gap: "5px",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <Button
                      onClick={clear}
                      icon={<TiTimes />}
                      style={{ background: "red" }}
                      type="primary"
                    >
                      Clear
                    </Button>
                  </div>

                  <div>
                    <Button
                      onClick={() => inputRef.current.click()}
                      icon={<IoMdCloudUpload />}
                      type="primary"
                    >
                      Upload
                    </Button>
                  </div>

                  {!signature && (
                    <div>
                      <Button
                        onClick={save}
                        icon={<IoIosSave />}
                        style={{ background: "green" }}
                        type="primary"
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            {/* {errorState.isError && (
              <p className="error-message">*{errorState.errorMessage}</p>
            )} */}
            <button
              onClick={handleSubmit}
              className="header-outlined-btn"
              size="small"
              type="submit"
            >
              I Agree
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AgreementModal;
