import React from "react";
import { Container } from "react-bootstrap";
import Header from "../components/header";
import NewFooter from "../components/NewFooter";

const PrivacyPolicy = (props) => {
  return (
    <>
      <Header title={props.metaTitle} desc={props.metaContent} />

      <div className="privacy-policy">
        <Container>
          <h1>Privacy policy</h1>
          <div className="privacy-policy-text">
            <p bis_size='{"x":20,"y":-2380,"w":533,"h":20,"abs_x":261,"abs_y":-1859}'>
              Effective Date: 29 Sep&nbsp;2023
            </p>
            <p bis_size='{"x":20,"y":-2346,"w":533,"h":124,"abs_x":261,"abs_y":-1825}'>
              At AtoZ Dispatch, we value and respect your privacy. This
              Privacy Policy outlines how we collect, use, disclose, and
              safeguard your personal information when you use our passenger and
              driver app. Please read this Privacy Policy carefully to
              understand our practices regarding your personal information and
              how we will treat it. By accessing or using our app, you
              acknowledge that you have read, understood, and agreed to be bound
              by the terms of this Privacy Policy.
            </p>

            <p bis_size='{"x":20,"y":-2208,"w":533,"h":20,"abs_x":261,"abs_y":-1687}'>
              ​
            </p>

            <ol bis_size='{"x":20,"y":-2175,"w":533,"h":20,"abs_x":261,"abs_y":-1654}'>
              <li bis_size='{"x":60,"y":-2175,"w":453,"h":20,"abs_x":301,"abs_y":-1654}'>
                <p bis_size='{"x":60,"y":-2175,"w":453,"h":20,"abs_x":301,"abs_y":-1654}'>
                  Information We Collect
                </p>
              </li>
            </ol>

            <p bis_size='{"x":20,"y":-2141,"w":533,"h":41,"abs_x":261,"abs_y":-1620}'>
              1.1 Personal Information We may collect personal information from
              you when you register an account, use our app, or communicate with
              us. This may include, but is not limited to:
            </p>

            <ul bis_size='{"x":20,"y":-2086,"w":533,"h":223,"abs_x":261,"abs_y":-1565}'>
              <li bis_size='{"x":60,"y":-2086,"w":453,"h":20,"abs_x":301,"abs_y":-1565}'>
                <p bis_size='{"x":60,"y":-2086,"w":453,"h":20,"abs_x":301,"abs_y":-1565}'>
                  Full name
                </p>
              </li>
              <li bis_size='{"x":60,"y":-2052,"w":453,"h":20,"abs_x":301,"abs_y":-1531}'>
                <p bis_size='{"x":60,"y":-2052,"w":453,"h":20,"abs_x":301,"abs_y":-1531}'>
                  Email address
                </p>
              </li>
              <li bis_size='{"x":60,"y":-2019,"w":453,"h":20,"abs_x":301,"abs_y":-1498}'>
                <p bis_size='{"x":60,"y":-2019,"w":453,"h":20,"abs_x":301,"abs_y":-1498}'>
                  Phone number
                </p>
              </li>
              <li bis_size='{"x":60,"y":-1985,"w":453,"h":20,"abs_x":301,"abs_y":-1464}'>
                <p bis_size='{"x":60,"y":-1985,"w":453,"h":20,"abs_x":301,"abs_y":-1464}'>
                  Profile picture
                </p>
              </li>
              <li bis_size='{"x":60,"y":-1951,"w":453,"h":20,"abs_x":301,"abs_y":-1430}'>
                <p bis_size='{"x":60,"y":-1951,"w":453,"h":20,"abs_x":301,"abs_y":-1430}'>
                  Payment information
                </p>
              </li>
              <li bis_size='{"x":60,"y":-1917,"w":453,"h":20,"abs_x":301,"abs_y":-1396}'>
                <p bis_size='{"x":60,"y":-1917,"w":453,"h":20,"abs_x":301,"abs_y":-1396}'>
                  Location information
                </p>
              </li>
              <li bis_size='{"x":60,"y":-1883,"w":453,"h":20,"abs_x":301,"abs_y":-1362}'>
                <p bis_size='{"x":60,"y":-1883,"w":453,"h":20,"abs_x":301,"abs_y":-1362}'>
                  Driver&#39;s license and insurance information (for drivers)\
                </p>
              </li>
            </ul>

            <p bis_size='{"x":20,"y":-1850,"w":533,"h":20,"abs_x":261,"abs_y":-1329}'>
              ​
            </p>

            <p bis_size='{"x":20,"y":-1816,"w":533,"h":41,"abs_x":261,"abs_y":-1295}'>
              1.2 Non-Personal Information In addition to personal information,
              we may collect non-personal information about your use of our app.
              This may include:
            </p>

            <ul bis_size='{"x":20,"y":-1761,"w":533,"h":75,"abs_x":261,"abs_y":-1240}'>
              <li bis_size='{"x":60,"y":-1761,"w":453,"h":41,"abs_x":301,"abs_y":-1240}'>
                <p bis_size='{"x":60,"y":-1761,"w":453,"h":41,"abs_x":301,"abs_y":-1240}'>
                  Device information (e.g., device type, operating system,
                  unique device identifiers)
                </p>
              </li>
              <li bis_size='{"x":60,"y":-1707,"w":453,"h":20,"abs_x":301,"abs_y":-1186}'>
                <p bis_size='{"x":60,"y":-1707,"w":453,"h":20,"abs_x":301,"abs_y":-1186}'>
                  App usage data (e.g., app features used, session duration,
                  interactions)
                </p>
              </li>
            </ul>

            <ol bis_size='{"x":20,"y":-1673,"w":533,"h":20,"abs_x":261,"abs_y":-1152}'>
              <li bis_size='{"x":60,"y":-1673,"w":453,"h":20,"abs_x":301,"abs_y":-1152}'>
                <p bis_size='{"x":60,"y":-1673,"w":453,"h":20,"abs_x":301,"abs_y":-1152}'>
                  Use of Information
                </p>
              </li>
            </ol>

            <p bis_size='{"x":20,"y":-1639,"w":533,"h":41,"abs_x":261,"abs_y":-1118}'>
              2.1 Personal Information We may use the personal information we
              collect for the following purposes:
            </p>

            <ul bis_size='{"x":20,"y":-1584,"w":533,"h":278,"abs_x":261,"abs_y":-1063}'>
              <li bis_size='{"x":60,"y":-1584,"w":453,"h":20,"abs_x":301,"abs_y":-1063}'>
                <p bis_size='{"x":60,"y":-1584,"w":453,"h":20,"abs_x":301,"abs_y":-1063}'>
                  To create and manage your account
                </p>
              </li>
              <li bis_size='{"x":60,"y":-1551,"w":453,"h":20,"abs_x":301,"abs_y":-1030}'>
                <p bis_size='{"x":60,"y":-1551,"w":453,"h":20,"abs_x":301,"abs_y":-1030}'>
                  To provide the services and features of our app
                </p>
              </li>
              <li bis_size='{"x":60,"y":-1517,"w":453,"h":20,"abs_x":301,"abs_y":-996}'>
                <p bis_size='{"x":60,"y":-1517,"w":453,"h":20,"abs_x":301,"abs_y":-996}'>
                  To process your transactions and payments
                </p>
              </li>
              <li bis_size='{"x":60,"y":-1483,"w":453,"h":20,"abs_x":301,"abs_y":-962}'>
                <p bis_size='{"x":60,"y":-1483,"w":453,"h":20,"abs_x":301,"abs_y":-962}'>
                  To communicate with you regarding your account and app-related
                  updates
                </p>
              </li>
              <li bis_size='{"x":60,"y":-1449,"w":453,"h":20,"abs_x":301,"abs_y":-928}'>
                <p bis_size='{"x":60,"y":-1449,"w":453,"h":20,"abs_x":301,"abs_y":-928}'>
                  To respond to your inquiries, requests, or feedback
                </p>
              </li>
              <li bis_size='{"x":60,"y":-1416,"w":453,"h":20,"abs_x":301,"abs_y":-895}'>
                <p bis_size='{"x":60,"y":-1416,"w":453,"h":20,"abs_x":301,"abs_y":-895}'>
                  To personalize and improve your app experience
                </p>
              </li>
              <li bis_size='{"x":60,"y":-1382,"w":453,"h":41,"abs_x":301,"abs_y":-861}'>
                <p bis_size='{"x":60,"y":-1382,"w":453,"h":41,"abs_x":301,"abs_y":-861}'>
                  To send you promotional offers, marketing materials, or
                  newsletters (with your consent)
                </p>
              </li>
              <li bis_size='{"x":60,"y":-1327,"w":453,"h":20,"abs_x":301,"abs_y":-806}'>
                <p bis_size='{"x":60,"y":-1327,"w":453,"h":20,"abs_x":301,"abs_y":-806}'>
                  To comply with legal obligations
                </p>
              </li>
            </ul>

            <p bis_size='{"x":20,"y":-1293,"w":533,"h":20,"abs_x":261,"abs_y":-772}'>
              ​
            </p>

            <p bis_size='{"x":20,"y":-1260,"w":533,"h":62,"abs_x":261,"abs_y":-739}'>
              2.2 Non-Personal Information We may use non-personal information
              for analytical and statistical purposes, to improve our app&#39;s
              performance, enhance user experience, and for other business
              purposes.
            </p>

            <p bis_size='{"x":20,"y":-1184,"w":533,"h":20,"abs_x":261,"abs_y":-663}'>
              ​
            </p>

            <ol bis_size='{"x":20,"y":-1150,"w":533,"h":20,"abs_x":261,"abs_y":-629}'>
              <li bis_size='{"x":60,"y":-1150,"w":453,"h":20,"abs_x":301,"abs_y":-629}'>
                <p bis_size='{"x":60,"y":-1150,"w":453,"h":20,"abs_x":301,"abs_y":-629}'>
                  Sharing of Information
                </p>
              </li>
            </ol>

            <p bis_size='{"x":20,"y":-1117,"w":533,"h":83,"abs_x":261,"abs_y":-596}'>
              3.1 Service Providers We may share your personal information with
              third-party service providers who assist us in operating our app,
              providing services to you, or conducting business activities on
              our behalf. These service providers are obligated to protect your
              information and may only use it for the specific purposes we have
              authorized.
            </p>

            <p bis_size='{"x":20,"y":-1020,"w":533,"h":62,"abs_x":261,"abs_y":-499}'>
              3.2 Legal Compliance and Protection We may disclose your personal
              information if required by law, legal process, or government
              request, or if we believe it is necessary to protect our rights,
              property, or safety, or the rights, property, or safety of others.
            </p>

            <p bis_size='{"x":20,"y":-945,"w":533,"h":41,"abs_x":261,"abs_y":-424}'>
              3.3 Business Transfers In the event of a merger, acquisition, or
              sale of all or a portion of our assets, your personal information
              may be transferred to the acquiring entity.
            </p>

            <p bis_size='{"x":20,"y":-890,"w":533,"h":20,"abs_x":261,"abs_y":-369}'>
              ​
            </p>

            <ol bis_size='{"x":20,"y":-857,"w":533,"h":20,"abs_x":261,"abs_y":-336}'>
              <li bis_size='{"x":60,"y":-857,"w":453,"h":20,"abs_x":301,"abs_y":-336}'>
                <p bis_size='{"x":60,"y":-857,"w":453,"h":20,"abs_x":301,"abs_y":-336}'>
                  Data Security
                </p>
              </li>
            </ol>

            <p bis_size='{"x":20,"y":-823,"w":533,"h":83,"abs_x":261,"abs_y":-302}'>
              We are committed to ensuring the security of your personal
              information. We employ reasonable security measures to protect
              against unauthorized access, alteration, disclosure, or
              destruction of your personal information. However, no method of
              transmission over the internet or electronic storage is 100%
              secure, and we cannot guarantee absolute security.
            </p>

            <p bis_size='{"x":20,"y":-727,"w":533,"h":20,"abs_x":261,"abs_y":-206}'>
              ​
            </p>

            <ol bis_size='{"x":20,"y":-693,"w":533,"h":20,"abs_x":261,"abs_y":-172}'>
              <li bis_size='{"x":60,"y":-693,"w":453,"h":20,"abs_x":301,"abs_y":-172}'>
                <p bis_size='{"x":60,"y":-693,"w":453,"h":20,"abs_x":301,"abs_y":-172}'>
                  Your Choices and Rights
                </p>
              </li>
            </ol>

            <p bis_size='{"x":20,"y":-659,"w":533,"h":62,"abs_x":261,"abs_y":-138}'>
              5.1 Account Information You may review, update, or delete your
              account information by accessing the relevant settings within the
              app. Please note that deleting your account may result in the loss
              of certain data and the inability to use some app features.
            </p>

            <p bis_size='{"x":20,"y":-584,"w":533,"h":83,"abs_x":261,"abs_y":-63}'>
              5.2 Marketing Communications You have the right to opt-out of
              receiving promotional communications from us by following the
              instructions provided in those communications or by contacting us
              directly. However, we may still send you non-promotional messages
              related to your account or our services.
            </p>

            <p bis_size='{"x":20,"y":-487,"w":533,"h":20,"abs_x":261,"abs_y":34}'>
              ​
            </p>

            <p bis_size='{"x":20,"y":-454,"w":533,"h":20,"abs_x":261,"abs_y":67}'>
              5.3 Do Not Track Signals Our app does not respond to &quot;Do Not
              Track&quot; signals.
            </p>

            <p bis_size='{"x":20,"y":-420,"w":533,"h":20,"abs_x":261,"abs_y":101}'>
              ​
            </p>

            <ol bis_size='{"x":20,"y":-386,"w":533,"h":20,"abs_x":261,"abs_y":135}'>
              <li bis_size='{"x":60,"y":-386,"w":453,"h":20,"abs_x":301,"abs_y":135}'>
                <p bis_size='{"x":60,"y":-386,"w":453,"h":20,"abs_x":301,"abs_y":135}'>
                  Children&#39;s Privacy
                </p>
              </li>
            </ol>

            <p bis_size='{"x":20,"y":-352,"w":533,"h":83,"abs_x":261,"abs_y":169}'>
              Our app is not intended for use by individuals under the age of
              18. We do not knowingly collect personal information from
              children. If you are a parent or guardian and believe your child
              has provided us with personal information, please contact us, and
              we will delete such information from our records.
            </p>

            <p bis_size='{"x":20,"y":-256,"w":533,"h":20,"abs_x":261,"abs_y":265}'>
              ​
            </p>

            <ol bis_size='{"x":20,"y":-222,"w":533,"h":20,"abs_x":261,"abs_y":299}'>
              <li bis_size='{"x":60,"y":-222,"w":453,"h":20,"abs_x":301,"abs_y":299}'>
                <p bis_size='{"x":60,"y":-222,"w":453,"h":20,"abs_x":301,"abs_y":299}'>
                  Changes to this Privacy Policy
                </p>
              </li>
            </ol>

            <p bis_size='{"x":20,"y":-188,"w":533,"h":62,"abs_x":261,"abs_y":333}'>
              We may update this Privacy Policy from time to time. The updated
              version will be posted on our app, and the &quot;Effective
              Date&quot; at the top of this policy will reflect the date of the
              latest revision. We encourage you to review this Privacy Policy
              periodically for any changes.
            </p>

            <p bis_size='{"x":20,"y":-113,"w":533,"h":20,"abs_x":261,"abs_y":408}'>
              ​
            </p>

            <ol bis_size='{"x":20,"y":-79,"w":533,"h":20,"abs_x":261,"abs_y":442}'>
              <li bis_size='{"x":60,"y":-79,"w":453,"h":20,"abs_x":301,"abs_y":442}'>
                <p bis_size='{"x":60,"y":-79,"w":453,"h":20,"abs_x":301,"abs_y":442}'>
                  Contact Us
                </p>
              </li>
            </ol>

            <p bis_size='{"x":20,"y":-45,"w":533,"h":41,"abs_x":261,"abs_y":476}'>
              For further assistance or inquiries, please contact our customer
              support team at &nbsp;
              <a
                bis_size='{"x":198,"y":-22,"w":41,"h":15,"abs_x":439,"abs_y":499}'
                data-auto-recognition="true"
                href="mailto:info@coredreamsltd.com"
              >
                info@coredreamsltd.com
              </a>
            </p>

            <p bis_size='{"x":20,"y":8,"w":533,"h":20,"abs_x":261,"abs_y":529}'>
              ​
            </p>

            <p bis_size='{"x":20,"y":42,"w":533,"h":20,"abs_x":261,"abs_y":563}'>
              Conclusion
            </p>

            <p bis_size='{"x":20,"y":76,"w":533,"h":103,"abs_x":261,"abs_y":597}'>
              Your privacy is important to us at AtoZ Dispatch&nbsp;We are
              committed to protecting your personal information and maintaining
              its confidentiality. By using our passenger and driver app, you
              consent to the collection, use, and disclosure of your information
              as described in this Privacy Policy. We encourage you to read this
              Privacy Policy carefully and contact us if you have any questions
              or concerns.
            </p>
            <p>
              Core Dreams Innovations Pvt Ltd reserves the right to update or
              modify this policy at any time without prior notice.
            </p>
          </div>
        </Container>
      </div>

      <NewFooter />
    </>
  );
};
export default PrivacyPolicy;
