import React, { useState } from "react";
import { Container } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { BsGlobeEuropeAfrica } from "react-icons/bs";
import { postRequest } from "../../Helpers/APIHelper";
import { CircularProgress } from "@material-ui/core";

const NewContactUs = () => {
  const [captchaValue, setCaptchaValue] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!captchaValue) {
      alert("Please verify that you are not a robot.");
      return;
    }
    setBtnDisable(true);
    postRequest("send-contact-us", formData)
      .then((response) => {
        setBtnDisable(false);
        if (response.status) {
          alert(response?.message ?? "");
          window.location.reload();
        } else {
          if (response?.errors ?? false) {
            alert(response?.message ?? "");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error ?? "-");
      });
  };

  return (
    <>
      <Container>
        <div className="contact-form">
          <h3>Contact Us</h3>
          <div className="form-container">
            {/* <div className="form-left">
                            <h4>Contact Us</h4>
                            <div className="address-group">
                                <div className="left-contact">
                                    <div className="icon-ctn">
                                        {" "}
                                        <FiPhone size={20} />{" "}
                                    </div>
                                    <label>
                                        Phone : <span>+98 765 4321</span>{" "}
                                    </label>
                                </div>
                                <div className="left-contact">
                                    <div className="icon-ctn">
                                        {" "}
                                        <MdOutlineMailOutline size={20} />{" "}
                                    </div>
                                    <label>
                                        Email : <span>+98 765 4321</span>{" "}
                                    </label>
                                </div>
                                <div className="left-contact">
                                    <div className="icon-ctn">
                                        {" "}
                                        <BsGlobeEuropeAfrica size={20} />{" "}
                                    </div>
                                    <label>
                                        Website : <span>+98 765 4321</span>{" "}
                                    </label>
                                </div>
                            </div>
                        </div> */}

            {/* <form onSubmit={handleSubmit}>
              <div className="form-right">
                <div className="contact-div">
                  <label className="input-label">Name</label>
                  <input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    type="text"
                  />
                </div>
                <div className="flex-input-group">
                  <div className="contact-div width-50">
                    <label className="input-label">Phone No.</label>
                    <input
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      required
                      type="text"
                    />
                  </div>
                  <div className="contact-div width-50">
                    <label className="input-label">Email</label>
                    <input
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                      type="text"
                    />
                  </div>
                </div>
                <div className="contact-div">
                  <label className="input-label">Message</label>
                  <textarea
                    placeholder="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    className="text-areas"
                    cols={10}
                    rows={5}
                  ></textarea>
                </div>
                <div className="check-div">
                  <input
                    className="check-input"
                    id="check-input"
                    type="checkbox"
                  />
                  <label className="check-label" htmlFor="check-input">
                    I agree to receive the marketing and offers email from the
                    company
                  </label>
                </div>
                <div>
                  <ReCAPTCHA
                    sitekey="6Ldoys0iAAAAAKMK-xJT3IntXQFFcIgWotiVhaKG"
                    onChange={handleCaptchaChange}
                  />
                </div>
                <div className="submit-div">
                  <button
                    type="submit"
                    disabled={btnDisable}
                    className="submit-button"
                  >
                    Submit {btnDisable && <CircularProgress size={20} />}{" "}
                  </button>
                </div>
              </div>
            </form> */}

            <iframe
              title="request-contact-demo"
              width="3000"
              height="900"
              src="https://654ad6eb.sibforms.com/serve/MUIFACw-BzdAwiFzTQP-ZTKUgltcUFSqq3-QURzq0LUZNpa9kP4-CbnQbsOdvz1h9nMGHR3ifIS0YJcy7kKwkSoxT451PmR9FKeDqR_osmyCMooKiXPV5H7xgV1iYZ3blJGwiaujvaIL9hDTjI_5yCtiBMg763AMauBZEg9wpjoxQaGU1IYdu2mIIZzr4iMgxAmvNoSyy_ArGMvq "
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
                maxHeight: "100vh",
              }}
            ></iframe>

            {/* <iframe
              width="540"
              height="305"
              src="https://654ad6eb.sibforms.com/serve/MUIFACw-BzdAwiFzTQP-ZTKUgltcUFSqq3-QURzq0LUZNpa9kP4-CbnQbsOdvz1h9nMGHR3ifIS0YJcy7kKwkSoxT451PmR9FKeDqR_osmyCMooKiXPV5H7xgV1iYZ3blJGwiaujvaIL9hDTjI_5yCtiBMg763AMauBZEg9wpjoxQaGU1IYdu2mIIZzr4iMgxAmvNoSyy_ArGMvq "
              frameborder="0"
              scrolling="auto"
              allowfullscreen
              style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"
            ></iframe> */}
          </div>
        </div>
      </Container>
    </>
  );
};

export default NewContactUs;
