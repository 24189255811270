import { Modal } from "antd";
import React from "react";
import { FaLaptop } from "react-icons/fa";
import { FaAppStoreIos } from "react-icons/fa";
import { FaGooglePlay } from "react-icons/fa";
import web_play from "../../assets/image/laptop.png";
import ios_play from "../../assets/image/ios-play.png";
import google_play from "../../assets/image/google-play.png";
import { Link } from "react-router-dom";

const LiveDemoModal = ({ open, handleClose }) => {
  return (
    <>
      <div>
        <Modal
          width={400}
          footer={null}
          centered
          open={open}
          style={{ padding: "0px" }}
          onCancel={handleClose}
        >
          <div className="live-demo-modal">
            <h4>Booking App Demo</h4>
            <div>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                target="_blank"
                to={"https://yourcompany.atozdispatch.com/"}
              >
                <p>
                  {" "}
                  <div className="">
                    <img
                      src={web_play}
                      style={{
                        width: "40px",
                        height: "40px",
                        objectFit: "contain",
                      }}
                      alt="play-logo"
                    />
                    {/* <FaLaptop size={20} /> */}
                  </div>
                  Web Version
                </p>
              </Link>

              <Link
                style={{ textDecoration: "none", color: "black" }}
                target="_blank"
                to={
                  "https://play.google.com/store/apps/details?id=com.passenger.a2zdispatch&hl=en"
                }
              >
                <p>
                  {" "}
                  <div className="">
                    <img
                      src={google_play}
                      style={{
                        width: "40px",
                        height: "40px",
                        objectFit: "contain",
                      }}
                      alt="play-logo"
                    />
                  </div>
                  Android Version
                </p>
              </Link>

              <Link
                style={{ textDecoration: "none", color: "black" }}
                target="_blank"
                to={"https://apps.apple.com/tr/app/a2zdispatch/id6467181791"}
              >
                <p>
                  {" "}
                  <div className="">
                    <img
                      src={ios_play}
                      style={{
                        width: "40px",
                        height: "40px",
                        objectFit: "contain",
                      }}
                      alt="play-logo"
                    />
                  </div>
                  ios Version
                </p>
              </Link>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default LiveDemoModal;
