import { Modal } from "antd";
import React from "react";
import logo from "../../assets/image/logo.svg";
import { Link } from "react-router-dom";

const ThankYouModal = ({ open, handleClose, info }) => {
  return (
    <>
      <Modal
        footer={null}
        open={open}
        centered
        width={1000}
        onCancel={handleClose}
      >
        <div className=" thank-you-ctn">
          <div style={{ textAlign: "center" }}>
            <img
              style={{
                width: "80px",
                height: "80px",
                objectFit: "contain",
                marginBottom: "12px",
              }}
              src={logo}
              alt=""
            />
          </div>
          <h3
            style={{
              fontWeight: "bold",
              fontSize: "22px",
              textAlign: "center",
            }}
          >
            Thank you for Subscribing
          </h3>
          <p className="p1">
            You're subscribed to{" "}
            <span style={{ fontWeight: "bold" }}>{info?.plan} Package</span>{" "}
            Your application is being prepared and will be live shortly.
          </p>
          {/* <p className="p2">
            Your application will be live at this URL: [Application URL].
          </p> */}
          <p className="p3">
            [Note: To make your application live on your registered domain,
            please request your domain name in Signup form.]
          </p>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Link style={{ textDecoration: "none" }} to={"/"}>
              <button className="header-outlined-btn">Go To Home</button>{" "}
            </Link>
          </div>

          {/* ---contact--- */}
          <div className="contact-ctn">
            <h2>Need Help ?</h2>
            <p>Visit Our Software Guide to set up your account. </p>
            <p>Submit a ticket from your admin dashboard. Support Ticket</p>
            <h3>Stay Connected</h3>
            <div></div>
            <p>
              We’re here to ensure you have a seamless and productive
              experience.
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ThankYouModal;
