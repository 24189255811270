import React from "react";
import image_phone from "../assets/image/atoz-test-banner.png";
// import image_car from "../assets/image/K.png";
import { Link, useNavigate } from "react-router-dom";
import appStoreLogo from "../assets/image/App_Store_Icon.webp";
import googlePlayLogo from "../assets/image/Google_Play_Store_icon.png";

const BannerOne = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container-banner">
        <div className="left">
          <div className="text-ctn">
            <h1 style={{ color: "#000" }}>
              {" "}
              <span style={{ color: "white" }}> limo & chauffeur</span> dispatch
              system
            </h1>
            <h1 className="text2">upgrade your limo business</h1>
          </div>
        </div>

        <div className="right">
          <Link target="_blank" to={"/signup"}>
            <div className="image-ctn">
              <img loading="eager" className="img_1" src={image_phone} alt="" />
            </div>
          </Link>
          <div className=" download-section ">
            <Link
              target="_blank"
              to={"https://apps.apple.com/us/app/a-to-z-dispatch/id6639615450"}
            >
              <img
                style={{
                  height: "55px",
                  width: "170px",
                  objectFit: "contain",
                }}
                width={200}
                src={appStoreLogo}
                alt="app-store"
              />
            </Link>
            <Link
              target="_blank"
              to={
                "https://play.google.com/store/apps/details?id=com.a2z.dispatch"
              }
            >
              <img
                style={{
                  objectFit: "contain",
                  width: "170px",
                  height: "55px",
                }}
                width={200}
                src={googlePlayLogo}
                alt="google-play"
              />
            </Link>
          </div>
          <div
            className="hide-in-small"
            style={{ width: "100%", textAlign: "center" }}
          >
            <button
              onClick={() => navigate("/signup")}
              className="signup-button"
            >
              {" "}
              <span>signup for</span> Free trial
            </button>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            background: "#E86713",
            top: "0px",
            left: "0px",
            right: "0px",
            height: "100%",
            zIndex: "-10",
          }}
        ></div>
      </div>
    </>
  );
};

export default BannerOne;
