import React from "react";
import { Container } from "react-bootstrap";
import Header from "../components/header";
import NewFooter from "../components/NewFooter";

const CookiesPolicy = (props) => {
  return (
    <>
      <Header title={props.metaTitle} desc={props.metaContent} />
      <div className="cookies-policy">
        <Container>
          <h1>cookies policy</h1>
          <div className="privacy-policy-text">
            <h5>
              At AtoZ Dispatch, we use cookies to improve the functionality
              and performance of our website and to provide you with a better
              browsing experience. This Cookies Policy explains how we use
              cookies on our website and how you can manage them.
            </h5>
            <h6>What are Cookies? </h6>
            <p>
              Cookies are small text files that are placed on your device when
              you visit our website. They are widely used by website owners to
              make their websites work, or to work more efficiently, as well as
              to provide information to the website owner or third parties.
              Cookies can be "session" or "persistent" cookies. Session cookies
              are temporary and expire when you close your browser, while
              persistent cookies remain on your device until they expire or are
              deleted.
            </p>

            <h6>How We Use Cookies?</h6>
            <p>
              We use cookies to: • Provide essential features and functionality
              on our website, such as remembering your preferences, login
              information, and language settings. • Analyze how visitors use our
              website and track website performance, such as which pages are
              most frequently visited and which areas of our website are most
              popular. • Personalize your browsing experience by showing you
              content and advertisements that are relevant to you.
            </p>

            <h6>Types of Cookies We Use</h6>
            <p>
              We use both session and persistent cookies on our website. The
              types of cookies we use include: • Essential cookies: These
              cookies are necessary for our website to function properly, and
              they cannot be turned off in our system. • Performance cookies:
              These cookies collect information about how visitors use our
              website, such as which pages are visited most frequently and which
              areas of the website are most popular. • Functionality cookies:
              These cookies allow our website to remember choices you make, such
              as your login information and language settings. •
              Targeting/Advertising cookies: These cookies are used to deliver
              advertisements that are relevant to you and your interests.
            </p>

            <h6>Managing Cookies</h6>
            <p>
              You can manage cookies by adjusting your browser settings to
              refuse some or all cookies. However, if you choose to disable or
              reject cookies, some parts of our website may not function
              properly, and your browsing experience may be impacted.
            </p>

            <h6>Third-Party Cookies</h6>
            <p>
              We may use third-party cookies on our website to provide you with
              relevant content and advertisements. These cookies are placed by
              third-party advertising networks or social media platforms, and
              they may track your browsing activity across different websites
              and online services.
            </p>

            <h6>Changes to this Cookies Policy</h6>
            <p>
              We may update this Cookies Policy from time to time to reflect
              changes in our use of cookies or to comply with legal
              requirements. We encourage you to review this policy regularly to
              stay informed about our use of cookies.
            </p>

            <h6>Contact Us</h6>
            <p>
              If you have any questions about this Cookies Policy or our use of
              cookies, please contact us at support@atozdispatch.com
            </p>
          </div>
        </Container>
      </div>
      <NewFooter />
    </>
  );
};
export default CookiesPolicy;
